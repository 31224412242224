import React from 'react'
import classnames from 'classnames'

import Page from '../../Page'
import Text from '../../Text'
import Button from '../../Button'
import SelectInput from '../../SelectInput'
import SuccessStories from '../../SuccessStories'
import TerritoriesBlock from '../../TerritoriesBlock'
import Offices from '../../offices'
import { getUserCountry } from '../../../utilities/getUserCountry'

const supportedCountries = ['Nigeria', 'Kenya']

class ContactOverview extends React.Component {
  constructor (props) {
    super(props)
    const userCountry = getUserCountry()
    let currentCountry = null
    if (supportedCountries.includes(userCountry)) {
      currentCountry = {
        value: userCountry,
        label: userCountry
      }
    }

    this.state = {
      selectedCountry: currentCountry
    }
  }

  changeCountrySelection = (newCountry) => {
    const { selectedCountry } = this.state
    if (selectedCountry !== newCountry) {
      this.setState({ selectedCountry: newCountry })
    }
  }

  selectCountry = (country) => {
    this.changeCountrySelection({ value: country, label: country })
  }

  render () {
    const {
      offices,
      stories,
      territories
    } = this.props

    const { selectedCountry } = this.state

    const countryOptions = supportedCountries.map((val) => ({
      value: val,
      label: val
    }))

    const countriesToShow = selectedCountry ? [selectedCountry.value] : supportedCountries

    return (
      <Page.Panel
        noPadding
        color='grey'
        fullWidth
        texture={selectedCountry ? selectedCountry.label : 'africa'}
        className='contact-page__container'
      >
        <Page.Panel.Section hasPadding>
          <div className={classnames(
            'contact-page__select', 'fb-input__wrapper',
            { 'contact-page__select--selected': selectedCountry }
          )}>
            <label className='fb-input__label fb-input__label--big'>
              Select a country to see more:
            </label>
            <SelectInput.Stateless
              searchable={false}
              closeOnSelect
              value={selectedCountry}
              placeholder='Select country'
              options={countryOptions}
              onChange={this.changeCountrySelection}
            />
          </div>
          <div className={classnames(
            'contact-page__country-image-wrapper',
            {'contact-page__country-image-wrapper--open': selectedCountry}
          )}>
            <img
              className={classnames(
                'contact-page__country-image',
                {'contact-page__country-image--visible': selectedCountry && selectedCountry.label === 'Kenya'}
              )}
              src={`/assets/images/kenya.png`}
            />
            <img
              className={classnames(
                'contact-page__country-image',
                {'contact-page__country-image--visible': selectedCountry && selectedCountry.label === 'Nigeria'}
              )}
              src={`/assets/images/nigeria.png`}
            />
          </div>
          {countriesToShow.map((country) => (
            <TerritoriesBlock
              key={country}
              territories={territories[country]}
              country={country}
              isExtended={selectedCountry}
              onClickCountry={(country) => this.selectCountry(country)}
            />
          ))}
          <Button cta href='/signup/'>
            Sign up for Shelf Life
          </Button>
        </Page.Panel.Section>
        <Page.Panel.Section>
          <SuccessStories
            isThemed={false}
            withSignup={false}
            withOpenItem={false}
            stories={stories}
          />
        </Page.Panel.Section>
        <Page.Panel.Section hasPadding>
          <Text.Heading
            size='xlarge'
            className='contact-page__panel-header'
          >
            Our headquarters
          </Text.Heading>
          <Offices offices={offices} />
        </Page.Panel.Section>
      </Page.Panel>
    )
  }
}

export default ContactOverview
