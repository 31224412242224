import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Text from '../Text'
import Arrow from '../../icons/Arrow'

class TerritoriesBlock extends React.Component {
  render () {
    const {
      territories,
      country,
      isExtended,
      className,
      onClickCountry
    } = this.props

    return (
      <div
        className={classNames(
          'fb-territories-block',
          className
        )}
      >
        <Text.Heading
          size='large'
          className={classNames(
            'fb-territories-block__header',
            {'fb-territories-block__header--clickable': !isExtended}
          )}
          onClick={() => onClickCountry(country)}
        >
          {country} {!isExtended && <Arrow direction='right' />}
        </Text.Heading>
        <Text>
          Servicing the following states:
        </Text>

        {isExtended ? (
          <ul className='fb-territories-block__list fb-territories-block__list--extended'>
            {territories.map((item, idx) => (
              <li
                key={item.node.name}
                className='fb-territories-block__list-item fb-territories-block__list-item--extended'
              >
                <Text weight='bold'>
                  {item.node.name}
                </Text>
                <Text>
                  {item.node.phoneNumber}
                </Text>
              </li>
            ))}
          </ul>
        ) : (
          <ul className='fb-territories-block__list'>
            {territories.map((item, idx) => (
              <li
                key={item.node.name}
                className='fb-territories-block__list-item'
              >
                {item.node.name}
              </li>
            ))}
          </ul>
        )}
      </div>
    )
  }
}

TerritoriesBlock.propTypes = {
  territories: PropTypes.array,
  isExtended: PropTypes.bool
}

TerritoriesBlock.defaultProps = {
  isExtended: false,
  territories: []
}

export default TerritoriesBlock
