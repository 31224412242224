import React from 'react'
import get from 'lodash/get'
import { graphql } from 'gatsby'

import ContentfulPage from '../components/Page/ContentfulPage'
import SignUp from '../components/SL/SignUp'

import withContentfulBlocks from '../components/withContentfulBlocks'
import ContactOverview from '../components/SL/Contact/ContactOverview'

class ContactIndex extends React.Component {
  render () {
    const offices = get(this.props, 'data.allContentfulLocation.edges')
    const stories = get(this.props, 'data.stories.edges')
    const territoriesNg = get(this.props, 'data.territoriesNg.edges')
    const territoriesKe = get(this.props, 'data.territoriesKe.edges')

    const territories = {
      'Nigeria': territoriesNg,
      'Kenya': territoriesKe
    }

    return (
      <ContentfulPage
        blocks={this.props.blocks}
        footerColor='white'
        siteName={get(this.props, 'data.site.siteMetadata.siteName')}
        pageTitle={`${get(this.props, 'data.site.siteMetadata.siteName')} / Contact`}
        pageUrl={`${get(this.props, 'data.site.siteMetadata.url')}/contact`}
      >
        <ContactOverview
          offices={offices}
          stories={stories}
          territories={territories}
        />
        <SignUp />
      </ContentfulPage>
    )
  }
}

export default withContentfulBlocks(ContactIndex)

export const pageQuery = graphql`
  query SLContactQuery {
    site {
      ...SiteMetaDataFragment
    }
    page: contentfulPage(contentful_id: { eq: "1f7v1F9owIOXaRwx8qz4yg" }) {
      ...PageBlocksFragment
    }
    allContentfulLocation(sort: { fields: [title], order: ASC }) {
      edges {
        node {
          ...LocationFragment
        }
      }
    }
    stories: allContentfulShelfLifeSuccessStory{
      edges { node {
       name,
       pharmacyName,
       quote{quote},
       url,
       picture {
        id,
        fluid(
          quality: 80
          maxWidth: 2800
          resizingBehavior: FILL
        ) {
          ...GatsbyContentfulFluid_withWebp
          }
          file {
          url
          }
       }
      }
     }
    }
    territoriesNg: allContentfulShelfLifeTerritory(
      filter: { country: { eq: "nigeria" }}, sort: { fields: [name], order: ASC }
    ) {
      edges { node {
       name,
       country,
       phoneNumber
      }
     }
    }
    territoriesKe: allContentfulShelfLifeTerritory(
      filter: { country: { eq: "kenya" }}, sort: { fields: [name], order: ASC }
    ) {
      edges { node {
       name,
       country,
       phoneNumber
      }
     }
    }
  }
`
